// import "./styles.css";
import { useNavigate } from "react-router-dom";

export const ErrorNotFound = () => {
  const navigate = useNavigate();

  return (
    <div>
      <p>Error 404</p>
      <p>Página no encontrada</p>
      <button onClick={() => navigate("/")}>Volver al inicio</button>
    </div>
  );
};
