import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Auth } from "./pages/auth/index";
import { ExpensePage } from "./pages/expense-page/index";
import { AccountPage } from "./pages/account-page/index";
import { OverviewPage } from "./pages/overview-page";
import { ErrorNotFound } from "./pages/error-page";
import { Nav } from "./components/Nav";

function App() {
  return (
    <div className="React-app">
      <Router>
        <Nav />
        <Routes>
          <Route path="/" exact element={<Auth />} />
          <Route path="/expense-page" exact element={<ExpensePage />} />
          <Route path="/account-page" exact element={<AccountPage />} />
          <Route path="/overview-page" exact element={<OverviewPage />} />
          <Route path="*" exact element={<ErrorNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
