import { useContext } from "react";
import { useModifyTransaction } from "../hooks/useModifyTransaction";
import { useGetTransactions } from "../hooks/useGetTransactions";
import { useGetAccounts } from "../hooks/useGetAccounts";
import { ExpensePageContext } from "../pages/expense-page";
import { toDdMmYyyy } from "../helpers/dateFormatHelper";
import { moneyFormat } from "../helpers/moneyFormatHelper";

export const TransactionList = () => {
  const { setUpdateTransactionInput } = useContext(ExpensePageContext);
  const { deleteTransaction } = useModifyTransaction();
  const { transactions } = useGetTransactions();
  const { accounts } = useGetAccounts();

  return (
    <div className="transaction-list">
      <h3 className="text-center">Transacciones</h3>
      <ul>
        {transactions.map((transaction) => {
          const fromAccount = accounts.find((account) => account.accountId === transaction.accountId) ?? { name: "cuenta eliminada" };
          let toAccount = accounts.find((account) => account.accountId === transaction.toAccountId);

          const { description, amount, trType, transactionId, date } = transaction;

          if (!toAccount && trType === "transfer") toAccount = { name: "cuenta eliminada" };

          return (
            <li key={transactionId} className="grid grid-cols-3 grid-rows-3 gap-2 max-w-xl m-auto mb-4 text-center border-blue-900">
              {/* DESCRIPCIÓN */}
              <p className="col-span-2 font-bold">{description}</p>
              {/* TIPO DE TRANSACCIÓN */}
              <div className="col-start-3 text-white">
                {(trType === "expense" && <p className="p-1 rounded-full bg-th-red">gasto</p>) ||
                  (trType === "income" && <p className="p-1 rounded-full bg-th-green">ingreso</p>) ||
                  (trType === "transfer" && <p className="p-1 rounded-full bg-th-yellow">transferencia</p>)}
              </div>
              {/* CANTIDAD */}
              <p className="row-start-2">{moneyFormat(amount)}</p>
              {/* CUENTAS */}
              {(trType === "expense" && (
                <p className="row-start-2">
                  De la cuenta: <br />
                  {fromAccount.name}
                </p>
              )) ||
                (trType === "income" && (
                  <>
                    <p className="row-start-2"></p>
                    <p className="row-start-2">
                      A la cuenta: <br />
                      {fromAccount.name}
                    </p>
                  </>
                )) ||
                (trType === "transfer" && (
                  <>
                    <p className="row-start-2">
                      De la cuenta: <br />
                      {fromAccount.name}
                    </p>
                    <p className="row-start-2">
                      A la cuenta: <br />
                      {toAccount?.name}
                    </p>
                  </>
                ))}

              {/* FECHA */}
              <p className="row-start-3">{toDdMmYyyy(date)}</p>
              {/* EDITAR */}
              <button
                onClick={() => {
                  setUpdateTransactionInput(transaction);
                  document.getElementById("updateTransactionPopup").showModal();
                }}
                className="row-start-3"
              >
                Editar{" "}
              </button>
              {/* BORRAR */}
              <button onClick={() => deleteTransaction(transactionId)} className="row-start-3">
                Borrar
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
